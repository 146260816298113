<template>
    <b-modal v-if="task && task.jobType" v-model="modalShow" :title="task.name + ' - Edit'" ok-title="Save" @ok="save">
        <div class="container-fluid k4-modal">
            <div class="row mb-2">
                <div class="col-3"><label class="col-form-label">Job id</label></div>
                <div class="col-9"><input class="form-control" disabled type="text"  v-model="task.jobId" /></div>
            </div>
            <div class="row mb-2">
                <div class="col-3"><label class="col-form-label">Job type</label></div>
                <div class="col-9"><input class="form-control" disabled type="text" v-model="task.jobType.value" /></div>
            </div>
            <div class="row mb-2">
                <div class="col-3"><label class="col-form-label">Job name</label></div>
                <div class="col-9"><input class="form-control" type="text" v-model="task.name" /></div>
            </div>
            <div class="row mb-2">
                <div class="col-3"><label class="col-form-label">Cron</label></div>
                <div class="col-9"><input class="form-control" type="text" v-model="task.cronExpression" /></div>
            </div>
            <div class="row mb-2">
                <div class="col-3"><label class="col-form-label">Ref Date</label></div>
                <div class="col-9"><input class="form-control" type="text" v-model="task.refDate" /></div>
            </div>
            <div class="row mb-2">
                <div class="col-3"><label class="col-form-label">End Date</label></div>
                <div class="col-9"><input class="form-control" type="text" v-model="task.endDate" /></div>
            </div>
        </div>
    </b-modal>
</template>
<script>
import toolsMixins from '../../mixins/tools.mixins';
import taskService from '../../services/task.service'
export default {
    name: "EditTaskModal",
    mixins: [toolsMixins],
    data: function () {
        return {
            modalShow: false,
            showJsonEditor: false, 
            task: {},
            refDate_Date: "",
            jConfig: {}
        };
    },
    watch: {
        modalShow: function (val) {
            if (!val) {
                this.task = {};
                this.logs = [];
                this.jConfig={};
                this.showJsonEditor=false;
                clearInterval(this.timer);
            }
        },
    },
    methods: {
        updateTask: function (task) {
            if (task) {
                taskService.updateTask(this.task);
            }
        },
        save: function () {
            if(this.task){
                taskService.updateTask(this.task).then(resp => {
                    var response = resp.status ? resp : resp.response;
                    if (response.status == 200) {
                        this.showToast("update task", response.data);
                    }
                    else {
                        this.showToast("error", response.data, "danger");
                    }
                });
            }
        },
        showModal: function () {
            this.modalShow = true;
        }
    }
}
</script>