<template>
    <div class="card k4-card-login shadow-lg">
        <div class="card-header">Key4Sync - Login</div>
        <form name="form" @submit.prevent="handleLogin">
            <div class="card-body">

                <div class="row">
                    <div class="col-md-3"><label for="inputUser">Email</label></div>
                    <div class="col-md-9"><input class="form-control" id="inputUser" type="email" v-model="user.username"></div>
                </div>
                <div class="row">
                    <div class="col-md-3"><label for="inputPass">Password</label></div>
                    <div class="col-md-9"><input class="form-control" id="inputPass" type="password" v-model="user.password"></div>
                </div>
                <div class="form-group">
                    <div v-if="message" class="alert py-2 mt-4 alert-danger">{{message}}</div>
                </div>
            </div>
            <div class="card-footer text-right">
                <button class="btn btn-primary bg-gradient">Login</button>
            </div>
        </form>
    </div>
</template>
<script>
import User from '../models/user';

export default {
    name:'Login',
    data:function(){
        return{
            user: new User('',''),
            message:''
        };
    },
    methods: {
        handleLogin: function(){
            if(this.user.username && this.user.password){
                this.$store.dispatch('auth/login',this.user).then(
                    () => {
                         this.$router.replace({ name : 'Dashboard'});
                    },
                    () => {
                        this.message = 'authentication failed !';
                    }
                );
            }
            else{
                this.message = 'missing user or password !';
            }
        }
    }
}
</script>
<style scoped>
    .k4-card-login{
        max-width: 500px;
        margin: 50px auto;
    }
.k4-card-login .card-header{background-color: var(--main-k4-primary-color);color: var(--main-top-font-color);}

    .k4-card-login .row{margin: 10px 0 10px -15px;}
    .k4-card-login .row>label{margin-top: 5px;}
</style>
