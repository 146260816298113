import axios from 'axios'


class RestService{

    GET() { return 'get'; }
    POST() {return 'post';}
    PUT() {return 'put';}
    DELETE() {return 'delete';}

    makeRequest(requestType, url, data, header){
        return axios[requestType.toLowerCase()](url, data, header).then(
            response => { return response; }, 
            error => { return Promise.reject(error); }
        )
    }
}

export default new RestService;