import RestService from './restService';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL_BASE + 'api/tasklog';

class TasklogService{
    getLogs(jobName, refDate){
        if(!jobName){ throw 'jobName is null'; }
        var url = API_URL + '/' + jobName; 
        if (refDate) {
            url += '?refTime='+ encodeURI(refDate)
        }
        return RestService.makeRequest(RestService.GET(),url,{ headers: authHeader() });
    }
}
export default new TasklogService;