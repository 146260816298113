<template>
    <b-modal v-model="modalShow" :title="modal_title" @ok="_confirm" @cancel="_cancel" @close="_cancel">
        <span v-html="modal_message"></span>
    </b-modal>
</template>
<script>


export default{
    name : "DialogConfirm",
    data : function() {
        return {
            modalShow : false,
            title : '', 
            mess : String,

            resolvePromise: undefined,
            rejectPromise: undefined,
        }
    },
    computed :{
        modal_title : function() { return this.title ?? 'default title'},
        modal_message : function() {return this.mess ?? 'default message'}
    },
    methods : {
        showModal(opts={}){
            this.title = opts.title;
            this.mess = opts.message;
            this.modalShow = true;

            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve
                this.rejectPromise = reject
            })
        }, 

        _confirm() {
            this.modalShow = false;
            this.resolvePromise(true);
        },
        _cancel() {
            this.modalShow = false;
            this.resolvePromise(false)
        },
    }
}
</script>
