<template>
    <div class="k4-menu-list-item">
        <div class="k4-menu-item">
            <router-link to="/">Home</router-link>
        </div>
        <div class="k4-menu-item">
            <router-link to="/dashboard">Dashboard</router-link>
        </div>
        <div class="k4-menu-item">
            <router-link to="/about">about</router-link>
        </div>
    </div>
</template>