<template>
    <b-modal modal-class="k4-modal-json-editor" v-if="task && task.jobType" v-model="modalShow" :title="task.name + ' - Edit Configuration'" ok-title="Save" size="xl" @ok="save">
        <vue-monaco-editor
            v-model="code"
            theme="vs-dark"
            :options="MONACO_EDITOR_OPTIONS"
            height="80vh"
            language="json"
        />
    </b-modal>
</template>
<script>
import toolsMixins from '../../mixins/tools.mixins';
import taskService from '../../services/task.service';


export default {
    name: "EditConfigModal",
    mixins: [toolsMixins],
    compatConfig: { MODE: 3 },
    data: function () {
        return {
            modalShow: false,
            code: '',
            task: {},
            MONACO_EDITOR_OPTIONS : {
                automaticLayout: true,
                formatOnType: true,
                formatOnPaste: true
            }
        };
    },
    watch:{
        modalShow(val){
            if(val){
                this.getConfig();    
            } else {
                this.task={};
            }
        }
    },
    methods: {
        getConfig(){
            taskService.getConfiguration(this.task.jobId).then(resp => {
                var response = resp.status ? resp : resp.response;
                if (response.status == 200) {
                    this.code = response.request.responseText;
                }
                else {
                    this.showToast("error", response.data, "danger");
                }
            });
        },
        save(){
            taskService.putConfiguration(this.task.jobId, this.code);
        },
        showModal() {
            this.modalShow = true;
        }
    },
    components: { }
}
</script>

<style>
    /* required class */
    .k4-modal-json-editor .my-editor {
        /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
        background: #2d2d2d;
        color: #ccc;

        /* you must provide font-family font-size line-height. Example: */
        font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
        font-size: 14px;
        line-height: 1.5;
        padding: 5px;
    }

    /* optional class for removing the outline */
    .k4-modal-json-editor .prism-editor__textarea:focus {
        outline: none;
    }

    .k4-modal-json-editor .prism-editor-wrapper {
        width: auto !important;
        display: inline-flex !important;
    }

    .k4-modal-json-editor .prism-editor-wrapper .prism-editor__editor, .prism-editor-wrapper .prism-editor__textarea {
        color: #fff;
        white-space: pre !important;
        overflow: hidden !important;
    }

    .k4-modal-json-editor .modal-dialog{
        overflow-x: initial !important
    }

    .k4-modal-json-editor .modal-body {
        overflow-x: auto !important;
        background: #2d2d2d;
    }

    .k4-modal-json-editor .modal-xl {
        max-width: 2000px !important;
    }
</style>
