import RestService from './restService';
import authHeader from './auth-header';
import authHeaderRaw from './auth-header-raw';

const API_URL = process.env.VUE_APP_API_URL_BASE + 'api/task';

class TaskService{

    getTasks(){
        return RestService.makeRequest(RestService.GET(),API_URL,{ headers: authHeader() });
    }

    startTask(jobId){
        if(!jobId){ throw 'jobId is null'; }
        var url = API_URL + "/" + jobId + "/start"
        return RestService.makeRequest(RestService.GET(),url,{ headers: authHeader() });
    }
    
    stopTask(jobId){
        if(!jobId){ throw 'jobId is null'; }
        var url = API_URL + "/" + jobId + "/stop"
        return RestService.makeRequest(RestService.GET(),url,{ headers: authHeader() });
    }

    runTask(jobId){
        if(!jobId){ throw 'jobId is null'; }
        var url = API_URL + "/" + jobId + "/run"
        return RestService.makeRequest(RestService.GET(),url,{ headers: authHeader() });
    }

    removeTask(jobId){
        if(!jobId){ throw 'jobId is null'; }
        var url = API_URL + "/" + jobId + "/remove"
        return RestService.makeRequest(RestService.DELETE(),url,{ headers: authHeader() });
    }

    updateTask(task){
        if(!task){ throw 'task is null'; }
        return RestService.makeRequest(RestService.PUT(), API_URL, task, {headers: authHeader() });
    }

    getConfiguration(jobId){
        if(!jobId){ throw 'jobId is null'; }
        var url = API_URL + "/" + jobId + "/configuration"
        return RestService.makeRequest(RestService.GET(), url, {headers : authHeader() });
    }

    putConfiguration(jobId, contents){
        if(!jobId){ throw 'jobId is null'; }
        var url = API_URL + "/" + jobId + "/configuration"
        return RestService.makeRequest(RestService.POST(), url, contents,{headers : authHeaderRaw() });
    }

    addTask(task,contents){
        if(!task){ throw 'task is null' }
        if(!contents){ throw 'json is null' }
        var url = API_URL + "/add";
        return RestService.makeRequest(RestService.PUT(), url, {task:task,configuration:contents} ,{headers : authHeader() });
    }

    addEmptyTask(task){
        if(!task){ throw 'task is null' }
        var url = API_URL + "/addEmpty";
        return RestService.makeRequest(RestService.PUT(), url, task ,{headers : authHeader() });
    }

}

export default new TaskService;