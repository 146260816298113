<template>
    <div class="k4-main-header shadow-lg">
        <div class="k4-logo-container">
            <router-link to ="/"><img class="k4-logo" src="../assets/Logo-k4-white.png" /></router-link>
        </div>
        <div class="k4-main-title">Key4Sync</div>
        <div class="k4-main-auth-container">
            {{userName}}
        </div>
    </div>
</template>
<script>
export default {
    name:'MainHeader',
    computed: {
       userName: function(){
            return this.$store.state.auth.user ? this.$store.state.auth.user.name : null;
        }
    }
}
</script>