<template>
  <div id="app" v-if="!isLogin">
    <MainHeader />
    <div id="nav" style="display:none;">
      <MainMenu />
    </div>
    <div class="k4-site-container">
      <router-view />
    </div>
  </div>
  <div id="app" v-else>
    <router-view />
  </div>
</template>

<script>
import MainMenu from './components/MainMenu';
import MainHeader from './components/MainHeader';

export default {
  name: 'MainApp',
  computed: {
    isLogin: function(){
      return this.$route.name == 'Login';
    }
  },
  components:{MainMenu, MainHeader}
}
</script>

