<template>
    <div class="k4-site">
        <div class="list-group shadow-sm">
            <div class="list-group-item text-white k4-group-list-header">
                <div class="row">
                    <div class="col-md-3 col-8 k4-list-title">Name</div>
                    <div class="col-md-1 col-4 k4-list-state">State</div>
                    <div class="col-md-5 row">
                        <div class="col-md-4 k4-list-next">Next Occurence</div>
                        <div class="col-md-4 k4-list-ref">Ref Date</div>
                        <div class="col-md-4 k4-list-end">End Date</div>
                    </div>
                    <div class="col-md-1 k4-list-error">Errors</div>
                    <div class="col-md-2 k4-list-action"></div>
                </div>
            </div>
            <div v-for="task in tasks" :key="task.jobId" class="list-group-item k4-group-list-item" :class="getTaskRowClass(task)">
                <div class="row">
                    <div class="col-md-3 col-8 k4-list-title k4-center">{{ task.name }}</div>
                    <div class="col-md-1 col-4 k4-list-state k4-center">{{ task.jobState.value }}</div>
                    <div class="col-md-5 row">
                        <div class="col-md-4 k4-list-next k4-center">
                            <span class="k4-media-s-show">Next</span>
                            <span v-if="task.jobState.value != 'STOP'">{{new Date(task.nextOccurence) | dateFormat('DD/MM/YYYY HH:mm:ss')}}</span>
                        </div>
                        <div class="col-md-4 k4-list-ref k4-center">
                            <span class="k4-media-s-show">ref</span>
                            {{new Date(task.refDate) | dateFormat('DD/MM/YYYY HH:mm:ss')}}
                        </div>
                        <div class="col-md-4 k4-list-end k4-center">
                            <span class="k4-media-s-show">End</span>
                            <span v-if="task.endDate">{{new Date(task.endDate) | dateFormat('DD/MM/YYYY HH:mm:ss')}}</span>
                        </div>
                    </div>
                    <div class="col-md-1 k4-list-error k4-center">
                        <span class="k4-media-s-show">Error</span>
                        <span v-if="task.comboOfError>0" >{{ task.comboOfError }}</span>
                    </div>
                    <div class="col-md-2 k4-list-action">
                        <b-dropdown variant="dark" text="Actions">
                            <b-dropdown-item href="#" @click.prevent="showDetails(task)">Activity</b-dropdown-item>
                            <b-dropdown-item href="#"  @click.prevent="showEditDetails(task)">Edit details</b-dropdown-item>
                            <b-dropdown-item href="#" v-if="windowWidth>767"  @click.prevent="showEditConfig(task)">Edit config</b-dropdown-item>
                            <b-dropdown-item href="#" @click.prevent="forceRun(task.jobId)">Force run</b-dropdown-item>

                            <b-dropdown-item v-if="task.jobState.value == 'STOP'" href="#" @click.prevent="removeTask(task)" class="k4-dropdown-item-danger">Remove</b-dropdown-item>
                            <b-dropdown-item v-else href="#" disabled>Remove</b-dropdown-item>
                            
                        </b-dropdown>
                        <b-button variant="dark" v-if="task.jobState.value == 'WAITING'" @click.prevent="stopTask(task.jobId)">Stop</b-button>
                        <b-button variant="dark" v-else-if="task.jobState.value == 'ERROR'" @click.prevent="stopTask(task.jobId)">Stop</b-button>
                        <b-button variant="dark" v-else-if="task.jobState.value == 'STOP'" @click.prevent="startTask(task.jobId)">Start</b-button>
                        <b-button variant="dark" v-else disabled>Stop</b-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="k4-bubble-info" v-if="windowWidth>767">
            <div class="bubble-detail">
                <span>{{ tasks.length }} jobs</span>
                <span>{{ activeTasks }} active</span>
            </div>
        </div>
        <b-button v-if="windowWidth>767" variant="success" class="k4-btn-new shadow-sm" @click.prevent="addTask()" size="lg">+ New</b-button>
        <DetailsModal ref="detailsModal" />
        <EditModal ref="editModal"/>
        <EditConfigModal ref="editConfigModal" />
        <CreateTaskModal ref="createTaskModal" />
        <DialogConfirm ref="confirm" />
    </div>
</template>  

<script>
import TaskService from '../services/task.service';
import DetailsModal from '../components/dashboard/DetailsModal.vue';
import EditModal from '../components/dashboard/EditTaskModal.vue';
import DialogConfirm from '../components/common/DialogConfirm.vue';
import ToolsMixin from '../mixins/tools.mixins';
import EditConfigModal from '../components/dashboard/EditConfigModal.vue';
import CreateTaskModal from '../components/dashboard/CreateTaskModal.vue';

export default {
    name: 'Dashboard',
    mixins:[ToolsMixin],
    components : { DetailsModal, EditModal, DialogConfirm, EditConfigModal, CreateTaskModal },
    data : function(){
        return {
            timer:'',
            tasks: [],
            error: 0,
            windowWidth: window.innerWidth
        }
    },
    computed:{
        activeTasks:function(){
            const activeStatus = ['WAITING', 'RUN', 'ONQEUE'];
            return this.tasks.filter(i => activeStatus.includes(i.jobState.value)).length
        }
    },
    created:function(){
        this.refreshTaskDetails();
        this.timer = setInterval(() => {this.refreshTaskDetails(); },500);
    },
    mounted(){
        window.addEventListener('resize', this.onResize);
    },
    beforeDestroy : function(){
        clearInterval(this.timer);
        window.removeEventListener('resize', this.onResize);
    },
    watch : {
        error : function(val){
            if(val >= 5 ){
                this.showToast('Error', 'Refresh has been ended.', 'danger');
                clearTimeout(this.timer);
            }
        }
    },
    methods: {
        refreshTaskDetails : function() {
            TaskService.getTasks().then(
                resp => {
                    var response = resp.status ? resp : resp.response; 
                    if(response.status == 200){
                        this.tasks = response.data.sort((a, b) => (a.name > b.name) ? 1 : -1);
                        this.error = 0;
                    } else {
                        this.showToast('Error', response.data, 'danger');
                    }
                }
            ).catch(
                error => 
                {
                    this.showToast('Error',(error ?? 'Server is offline.'),'danger');
                    this.error++;
                }
            );
        },

        getTaskRowClass(task){
            switch(task.jobState.value.toUpperCase()){
                case 'WAITING': return 'k4-row-waiting';
                case 'ON_QUEUE' : return 'k4-row-onqueue';
                case 'RUN': return 'k4-row-run';
                case 'STOP' : return task.comboOfError < 5 ? 'k4-row-stop' : 'k4-row-error-5';
                case 'ERROR': return task.comboOfError < 5 ? 'k4-row-error-0' : 'k4-row-error-5';
            }
        },

        startTask(jobId){
            TaskService.startTask(jobId).then(
                resp => {
                        var response = resp.status ? resp : resp.response; 
                    if(response.status == 200){
                        this.showToast('Information', 'Start job');
                        this.refreshTaskDetails();
                    }
                }
            );
        },

        stopTask(jobId){
            TaskService.stopTask(jobId).then(
                resp => {
                        var response = resp.status ? resp : resp.response; 
                    if(response.status == 200){
                        this.showToast('Information', 'Stop job');
                        this.refreshTaskDetails();
                    }
                }
            );
        },

        forceRun(jobId){
            TaskService.runTask(jobId);
            this.showToast('Information', "Run job");
            this.refreshTaskDetails();
        },

        removeTask(task){
            if(!task){ throw 'task is null.'; }

            this.$refs.confirm.showModal({
                title : "Remove task", 
                message : "Remove <b>" + task.name + "</b> ?"
            }).then((result) => {
                if(result){
                    TaskService.removeTask(task.jobId)
                    .then(
                        resp => {
                             var response = resp.status ? resp : resp.response; 
                            if(response.status == 200){
                                this.showToast('Information', 'Task ' & task.name & ' has been removed');
                                this.refreshTaskDetails();
                            } else {
                                this.showToast('Error', response.data, 'danger');
                            }
                        }
                    ).catch(
                        error => 
                        {
                            this.showToast('Error',error ?? 'Server is offline.','danger');
                            this.error++;
                        }
                    );
                }
            });
        },

        onResize(){
            this.windowWidth=window.innerWidth;
        },

        showDetails(task){
            if(!task){ throw 'task is null.'; }
            this.$refs.detailsModal.task = task; 
            this.$refs.detailsModal.showModal();
        },

        showEditDetails(task){
            if(!task){ throw 'task is null.'; }
            this.$refs.editModal.task = task; 
            this.$refs.editModal.showModal();
        },

        showEditConfig(task){
            if(!task){ throw 'task is null.'; }
            this.$refs.editConfigModal.task = task; 
            this.$refs.editConfigModal.showModal();
        },
        addTask(){
            this.$refs.createTaskModal.showModal();
        }
    }

}
</script>