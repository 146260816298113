<template>
    <b-modal v-model="modalShow" title="Create task without config" ok-title="Save" size="lg" @ok="save">
        <div class="container-fluid k4-modal">
            <div class="row mb-2">
                <div class="col-3"><label class="col-form-label">Job type</label></div>
                <div class="col-9"><input class="form-control" disabled type="text" v-model="task.jobType" /></div>
            </div>
            <div class="row mb-2">
                <div class="col-3"><label class="col-form-label">Job name</label></div>
                <div class="col-9"><input class="form-control" type="text" v-model="task.name" /></div>
            </div>
            <div class="row mb-2">
                <div class="col-3"><label class="col-form-label">Filename</label></div>
                <div class="col-9"><input class="form-control" type="text" v-model="task.filename" /></div>
            </div>
            <div class="row mb-2">
                <div class="col-3"><label class="col-form-label">Cron</label></div>
                <div class="col-9"><input class="form-control" type="text" v-model="task.cronExpression" /></div>
            </div>
            <div class="row mb-2">
                <div class="col-3"><label class="col-form-label">Alert Name</label></div>
                <div class="col-9"><input class="form-control" type="text" v-model="task.smsContact.name" placeholder="keep empty for default value" /></div>
            </div>
            <div class="row mb-2">
                <div class="col-3"><label class="col-form-label">Alert Phone Number (ex: 33601020304)</label></div>
                <div class="col-9"><input class="form-control" type="text" v-model="task.smsContact.phoneNumber" placeholder="keep empty for default value"/></div>
            </div>
        </div>
    </b-modal>
</template>
<script>
import toolsMixins from '../../mixins/tools.mixins';
import taskService from '../../services/task.service';
export default {
    name: "createTaskModal",
    mixins:[toolsMixins],
    data() {
        return {
            modalShow: false,
            defaultTask: {  jobType: "SCHEDULE_JOB", active:false, cronExpression: "0 */5 * ? * *", smsContact:{name: null, phoneNumber: null} },
            task: {name:"", filename: null, jobType: "SCHEDULE_JOB", active:false, cronExpression: "0 */5 * ? * *", smsContact:{name: null, phoneNumber: null} },
        };
    },
    watch: {
        modalShow(val) {
            if (!val) {
                this.task = this.defaultTask;
            }
        },
        'task.name' : function(val,oldval){
            if(val != oldval ){
                this.task.filename = (val==undefined || val!="") ? val + ".json" : "";
            }
        },
    },
    methods: {
        showModal() {
            this.modalShow = true;
        },
        save() {
            taskService.addEmptyTask(this.task).then(resp => {
                var response = resp.status ? resp : resp.response;
                if (response.status == 200) {
                    this.showToast("information", response.data);
                }
                else {
                    this.showToast("error", response.data, "danger");
                }
            });
        }
    },
}
</script>