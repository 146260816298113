<template>
    <b-modal v-if="task" v-model="modalShow" :title="task.name + ' - Details'" size="xl" ok-only ok-title="Close">
        <div class="container-fluid k4-modal">
            <div class="k4-form k4-form-disable">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row my-1">
                            <label for="task_name" class="col-sm-2 col-form-label">Name</label>
                            <div class="col-sm-10">
                                <input v-model="task.name" disabled class="form-control" id="task_name">
                            </div>
                        </div>
                        <div class="form-group row my-1">
                            <label for="task_type" class="col-sm-2 col-form-label">Type</label>
                            <div class="col-sm-10">
                                <input v-model="task.jobType.value" disabled class="form-control" id="task_type">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group row my-1">
                            <label for="taskId" class="col-sm-2 col-form-label">Task Id</label>
                            <div class="col-sm-10">
                                <input v-model="task.jobId" disabled class="form-control" id="taskId">
                            </div>
                        </div>
                        <div class="form-group row my-1">
                            <label for="task_cron" class="col-sm-2 col-form-label">Cron</label>
                            <div class="col-sm-10">
                                <input v-model="task.cronExpression" disabled class="form-control" id="task_cron">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="logs && logs.length > 0" class="my-1">
                <div class="k4-log-container">
                    <div class="k4-list-item" v-for="log,id in logs" v-bind:key="id" >
                        <div class="row">
                            <div class="col-md-2 col-4 text-nowrap">{{new Date(log.time) | dateFormat('DD/MM/YYYY HH:mm:ss.SSS')}}</div>
                            <div class="col-md-2 col-1">{{ log.level }}</div>
                            <div class="col-md-8 col-6 text-nowrap">{{ log.message }}</div>
                        </div>
                    </div>
                </div>
                <div class="pt-2">
                    <b-button variant="primary" @click.prevent="getLogs(false)">More logs</b-button>
                </div>
            </div>
        </div>
    </b-modal>    
</template>
<script>
import toolsMixins from '../../mixins/tools.mixins';
import TasklogService from '../../services/tasklog.service';

export default {
    name : 'detailsModal', 
    mixins : [toolsMixins],
    data : function(){
        return {
            task : '',
            logs : [],
            timer: '',
            modalShow : false
        };
    },
    watch : {
        task : function(val){
            if(val){
                this.getLogs(true);
                this.timer = setInterval(() => {this.getLogs(true); }, 5000)
            }
        },
        modalShow : function(val){
            if(!val){
                this.task = '';
                this.logs = [];
                clearInterval(this.timer);
            }
        }
    },
    methods : {
        getLogs : function(clearLogs){
            if(this.task){
                if(!clearLogs){ clearInterval(this.timer); }
                TasklogService.getLogs(this.task.name, (!clearLogs && this.logs && (this.logs.length > 0)) ? this.logs[this.logs.length - 1].time : undefined ).then(
                    resp => {
                        var response = resp.status ? resp : resp.response; 
                        if(response.status == 200){
                            if(response.data.length > 0){
                                this.logs = clearLogs ? response.data : this.logs.concat(response.data)
                            }
                        } else if(response.status == 404){
                            this.logs=[];
                        } else {
                            this.showToast('Error', response.data, 'danger');
                        }
                    }
                ).catch(
                    error => 
                    { 
                        this.logs = [];
                        this.showToast('Error', error.response.data,'danger'); 
                    }
                );
            }
        },
        showModal : function() {
            this.modalShow = true;
        }
    }
}
</script>

