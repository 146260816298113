import { createApp } from 'vue'
import { BootstrapVue } from 'bootstrap-vue'
import { install as VueMonacoEditorPlugin } from '@guolao/vue-monaco-editor'
import App from './App.vue'
import router from './router'
import store from './store'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';

// ===== Create a vue root instance =====
export const app = createApp(App);

// ===== Install instances to make the whole app aware of usable libraries =====
app.use(store);
app.use(router);
app.use(VueFilterDateFormat);
app.use(BootstrapVue);
app.use(VueMonacoEditorPlugin, {
    paths: {
      // The recommended CDN config
      vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.38.0/min/vs'
    },
  });

app.config.productionTip = false;

// ===== Mount =====
app.mount('#app');
